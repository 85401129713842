/* src/index.css */

/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set default font and background color */
body {
  font-family: Arial, sans-serif;
  background-color: #2c3e50; /* Darker blue-gray background */
  color: #fff; /* White text for better contrast */
}

/* Ensure the app container takes full height */
#root {
  min-height: 100vh;
}

/* Utility Classes */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
