/* App.css - Modern Design */

/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  /* Dark Mode Gradient Background with Animation */
  background: linear-gradient(135deg, #2b5876, #4e4376, #2C3E50, #4A00E0);
  background-size: 800% 800%;
  animation: gradientAnimation 20s ease infinite;
  color: #f0f0f0;
  transition: all 0.5s ease;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Modern Container Styles */
.container {
  background-color: rgba(30, 32, 44, 0.85);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  animation: containerFadeIn 0.7s ease-out;
  margin: 30px auto;
  max-width: 800px;
  transition: all 0.5s ease;
  transform-origin: center top;
  color: #f0f0f0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

@keyframes containerFadeIn {
  from {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.App {
  text-align: center;
  margin: 20px;
  animation: fadeIn 0.8s ease-out;
}

/* Modern Header Image */
.header-image {
  width: 180px;
  height: auto;
  margin: 0 auto 20px auto;
  display: block;
  border-radius: 50%;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  animation: floatAnimation 6s ease-in-out infinite;
  transition: all 0.5s ease;
  border: 3px solid rgba(167, 119, 227, 0.3);
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-15px) rotate(2deg);
  }
}

/* App Title Animation */
h1.my-4 {
  font-weight: 700;
  background: linear-gradient(45deg, #a777e3, #6e8efb);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: titlePulse 3s ease-in-out infinite;
  transition: all 0.5s ease;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  letter-spacing: 1px;
  text-shadow: 0 2px 10px rgba(167, 119, 227, 0.3);
}

@keyframes titlePulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

/* Quiz Started State - Compact Header */
.quiz-active .header-image {
  width: 50px;
  margin: 0 auto 5px auto;
  animation: none;
  transform: translateY(0);
  border-width: 2px;
}

.quiz-active h1.my-4 {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  transform: translateY(0);
  letter-spacing: 0.5px;
}

.quiz-active .container {
  padding-top: 10px;
}

/* Word Count Section */
.word-count {
  font-size: 1.2rem;
  animation: slideUp 0.7s ease-out;
  margin-bottom: 2rem;
  color: #f0f0f0;
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.word-count p {
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.word-count .btn-link {
  color: #a777e3;
  text-decoration: none !important;
  font-weight: bold;
  padding: 0;
  border: none;
  background: none;
  transition: all 0.3s ease;
  position: relative;
}

.word-count .btn-link:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #a777e3;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.word-count .btn-link:hover:after {
  transform: scaleX(1);
}

/* Modern Start Quiz Button */
.btn-start-quiz {
  background: linear-gradient(45deg, #6e8efb, #a777e3);
  border: none;
  color: white;
  padding: 15px 30px;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 8px 20px rgba(110, 142, 251, 0.3);
  animation: pulseAnimation 2s infinite;
  position: relative;
  overflow: hidden;
  letter-spacing: 1px;
}

.btn-start-quiz:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transition: all 0.5s ease;
}

.btn-start-quiz:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 25px rgba(110, 142, 251, 0.4);
}

.btn-start-quiz:hover:before {
  left: 100%;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Quiz Section Styling */
.quiz-section {
  animation: fadeIn 0.8s ease-out;
  transition: all 0.5s ease;
  color: #f0f0f0;
}

.quiz-section h2 {
  font-weight: 600;
  color: #a777e3;
  margin-bottom: 1.5rem;
  transition: all 0.5s ease;
  transform-origin: center;
  text-shadow: 0 2px 10px rgba(167, 119, 227, 0.3);
}

.quiz-active .quiz-section h2 {
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}

/* XP and Rank Container */
.xp-rank-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 20px;
  animation: slideUp 0.5s ease-out;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.xp-rank-container p {
  margin-bottom: 10px;
  font-weight: 500;
  color: #f0f0f0;
}

.progress {
  height: 20px !important;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.progress-bar {
  background: linear-gradient(45deg, #6e8efb, #a777e3);
  transition: width 1s ease;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Quiz Word Styling - Enhanced for better visibility */
.quiz-word {
  font-size: 1.8rem;
  margin-bottom: 1.8rem;
  animation: fadeIn 0.5s ease-out;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #f0f0f0;
}

.quiz-word strong {
  color: #a777e3;
  font-size: 2.2rem;
  display: block;
  margin-top: 10px;
  text-shadow: 0 2px 10px rgba(167, 119, 227, 0.3);
  letter-spacing: 1px;
}

/* Options Container - Enhanced for better visibility */
.options-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}

.option-button {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
  padding: 18px;
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  animation: slideInRight 0.5s forwards;
  animation-fill-mode: both;
  letter-spacing: 0.5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.option-text {
  color: #ffffff;
  font-weight: 600;
  display: block;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.option-button:hover {
  transform: translateY(-2px);
  border-color: #a777e3;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.12));
}

.option-button.correct {
  background: linear-gradient(145deg, rgba(40, 167, 69, 0.8), rgba(32, 201, 151, 0.8));
  color: white;
  border-color: #28a745;
  animation: correctAnswer 0.5s ease;
}

.option-button.incorrect {
  background-color: rgba(220, 53, 69, 0.2);
  border: 2px solid #dc3545;
  color: #f0f0f0;
  animation: incorrectAnswer 0.5s ease;
}

@keyframes correctAnswer {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes incorrectAnswer {
  0%, 20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Feedback Alert */
.alert {
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  animation: fadeIn 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.alert-success {
  background-color: #1a472a;  /* Darker green for feedback box */
  border: 2px solid #2e7d32;
  color: #ffffff;
}

.alert-danger {
  background-color: #8b0000;  /* Darker red for feedback box */
  border: 2px solid #c62828;
  color: #ffffff;
}

.feedback {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.feedback-timer {
  font-size: 0.9rem;
  opacity: 0.9;
}

/* Quiz Buttons */
.quiz-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.quiz-buttons .btn {
  padding: 12px 25px;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.btn-warning {
  background: linear-gradient(45deg, #ffc107, #ff9800);
  border: none;
  color: white;
}

.btn-success {
  background: linear-gradient(45deg, #28a745, #20c997);
  border: none;
  color: white;
}

.btn-danger {
  background: linear-gradient(45deg, #dc3545, #fd7e14);
  border: none;
  color: white;
  padding: 12px 25px;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Form Styling */
.add-word-form {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: slideUp 0.7s ease-out;
}

.form-control {
  background-color: rgba(30, 32, 44, 0.9) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: #f0f0f0 !important;
  border-radius: 10px !important;
  padding: 12px 15px !important;
  transition: all 0.3s ease !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(167, 119, 227, 0.25) !important;
  border-color: rgba(167, 119, 227, 0.5) !important;
}

/* Additional Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Quiz Content Animations */
.quiz-content {
  transition: all 0.3s ease;
  position: relative;
}

.quiz-content.animate-in {
  animation: fadeInUp 0.5s forwards;
}

.quiz-content.animate-out {
  animation: fadeOutDown 0.3s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .container {
    margin: 15px;
    padding: 20px;
  }
  
  h1.my-4 {
    font-size: 2rem;
  }
  
  .quiz-active h1.my-4 {
    font-size: 1rem;
  }
  
  .quiz-word {
    font-size: 1.5rem;
  }
  
  .quiz-word strong {
    font-size: 1.8rem;
  }
}